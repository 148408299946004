import ChartsEmbedSDK, {
  getRealmUserToken,
} from "@mongodb-js/charts-embed-dom";
import * as Realm from "realm-web";

const client = new Realm.App({
  id: "reporting-app-cmiix",
});

module.exports = client;

if (window.location.pathname == "/") {
document
  .getElementById("login-page")
  .addEventListener("input", () =>
    document.body.classList.toggle("error", false)
  );

document.addEventListener("DOMContentLoaded", function () {
  const passwordInput = document.getElementById("password");
  const eyeIcon = document.querySelector(".eye-icon");

  eyeIcon.addEventListener("click", function () {
    if (passwordInput.type === "password") {
      passwordInput.type = "text"; // Show the password
      eyeIcon.classList.add("open");
    } else {
      passwordInput.type = "password"; // Hide the password
      eyeIcon.classList.remove("open");
    }
  });
});

document
  .getElementById("loginButton")
  .addEventListener("click", () => tryLogin());

// Add an event listener to the password input field for Enter key press
document
  .getElementById("password")
  .addEventListener("keyup", (event) => {
    if (event.key === "Enter") {
      tryLogin();
    }
  });
}

document
.getElementById("logoutButton")
.addEventListener("click", () => logOut());

// Add dropdown value as query parameter and redirect to the next page accordingly
if (window.location.pathname.startsWith("/entry_page")) {
document
  .getElementById("scores-link")
  .addEventListener("click", function () {
    // const selectedAudience = document.getElementById("audience").value;
    //   if (selectedAudience) {
    //     window.location.href = `scores_dashboard.html?audience=${selectedAudience}`;
    //   }else{
    window.location.href = "scores_dashboard.html";
    // }
  });
document
  .getElementById("workplace-link")
  .addEventListener("click", function () {
    // const selectedAudience = document.getElementById("audience").value;
    // if (selectedAudience) {
    //   window.location.href = `higher_ed_dashboard.html?audience=${selectedAudience}`;
    // }else{
    window.location.href = "higher_ed_dashboard.html";
    // }
  });
}

function getUser() {
return document.getElementById("username").value;
}

function getPass() {
return document.getElementById("password").value;
}

async function logOut() {
await client.currentUser.logOut().then(() => {
  window.location.href = "/";
});
}

async function tryLogin() {
const credentials = Realm.Credentials.emailPassword(getUser(), getPass());

try {
  const user = await client.logIn(credentials);
  // Refresh a user's custom data to make sure we have the latest version
  await user.refreshCustomData();

  //if login for new users don't work, i might need to use client.currentUser instead of user
  console.log("currentUser company", client.currentUser.customData.company);

  // routing depending on what company is:
  const company = user.customData.company;
  if (company === "devs") {
    window.location.href = "dev_landing.html";
  } else if (company === "ytiq") {
    window.location.href = "entry_page.html";
  } else if (company === "campus_comms") {
    window.location.href = "entry_page_CI.html";
  }else if (company === "michigan") {
    window.location.href = "/LetsGrowMI/YouthPoll.html";
  }else if (company === "ihe"){
    window.location.href = "/InsideHigherEd/entry_page.html";
  }
} catch (err) {
  console.error("Authentication failed");
  document.body.classList.toggle("error", true);
}
}
